import React from "react";
import {Col, Container, Image,Row} from "react-bootstrap";
import classnames from "classnames";
import logo from "../../Assets/Images/logo6.svg";
import down from "../../Assets/Images/down.svg";
import up from "../../Assets/Images/up.svg";

import "./Block0.css";
import "./AllBlocks.css";

let dots =[];

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

class Animation extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dots: dots};

        this.updateAnimationState = this.updateAnimationState.bind(this);
    }

    pileOuFace()
    {
        const penny =  getRandomInt(2);
        if (penny)
            return 1;
        else
            return -1;
    }
    initDot(text)
    {
        const xmax = window.innerWidth;
        const ymax  = window.innerHeight;
        const RandX = xmax/2 + this.pileOuFace() * getRandomInt(xmax/2);
        const RandY = ymax/2 + this.pileOuFace() * getRandomInt(ymax/2);

        return({
            xTo : RandX,
            yTo : RandY,
            dx : 0,
            dy : 0,
            xFrom : RandX,
            yFrom : RandY,
            Text : text
        });
    }
    updateDots(dots)
    {
        const xmax = window.innerWidth;
        const ymax  = window.innerHeight;

        dots.forEach(dot => {
            if ((Math.abs(dot.xTo-dot.xFrom)<=Math.abs(dot.dx)) || ((Math.abs(dot.yTo-dot.yFrom)<=Math.abs(dot.dy))))
           {
               dot.xTo = xmax/2 + this.pileOuFace() * getRandomInt(xmax*3/4);
               dot.yTo = ymax/2 + this.pileOuFace() * getRandomInt(ymax*3/4);
               dot.dx = (dot.xTo-dot.xFrom) / (getRandomInt(50) + 300);
               dot.dy = (dot.yTo-dot.yFrom) / (getRandomInt(50) + 300);
               dot.xFrom +=  dot.dx;
               dot.yFrom += dot.dy;
               dot.thickness = getRandomInt(10)/2;
           }
           else
           {
               dot.xFrom +=  dot.dx;
               dot.yFrom += dot.dy;
           }
        });
    }
    componentDidMount() {
        for (let i=0;i<14;i++)
            dots.push(this.initDot("Conseil"));
        this.setState({dots:dots});
        this.rAF = requestAnimationFrame(this.updateAnimationState);
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.rAF);
    }

    updateAnimationState() {
        this.updateDots(dots);
        this.setState({dots:dots});
        this.rAF = requestAnimationFrame(this.updateAnimationState);
    }

    render() {
        return <Canvas dots={this.state.dots} />
    }
}

class Canvas extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    drawDot(ctx,x,y)
    {
        ctx.beginPath();
        ctx.fillStyle = '#AAAAAA';
        ctx.arc(x, y,9,0,2*Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.fillStyle = '#FFFFFF';
        ctx.arc(x, y ,7,0,2*Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.fillStyle = '#1E75BA';
        ctx.arc(x, y ,4,0,2*Math.PI);
        ctx.fill();
    }

    drawLinks(ctx,dots)
    {
        ctx.strokeStyle = '#AAAAAA';
       for(let i=0;i<dots.length;i++)
           for (let j=0;j<dots.length;j++)
               if (i!==j)
               {
                   ctx.beginPath();
                   ctx.lineWidth = dots[i].thickness;
                   ctx.moveTo(dots[i].xFrom, dots[i].yFrom);
                   ctx.lineTo(dots[j].xFrom, dots[j].yFrom);
                   ctx.stroke();
               }
    }

    componentDidUpdate() {
        const dots = this.props.dots;
        let canvas = this.canvasRef.current;
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        const ctx = canvas.getContext('2d');

        ctx.save();
        this.drawLinks(ctx,dots);
        dots.forEach( dot => this.drawDot(ctx,dot.xFrom,dot.yFrom));
        ctx.restore();
    }

    render() {
        return <canvas id="Blk0CvsId" ref={this.canvasRef} className={"Blk0Canvas"} ></canvas>;
    }
}


class Block0 extends React.Component{
    constructor(props) {
        super(props);

        this.state={logoStateCSS:"logo",upCSS:"upDisable",downCSS:"downEnable",position:0};

        this.handleDownScroll = this.handleDownScroll.bind(this);
        this.handleUpScroll=this.handleUpScroll.bind(this);
        this.handleScroll=this.handleScroll.bind(this);

        this.blocks =  ['block0','block1','block2','footer'];
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(event) {

        this.setState({logoStateCSS: "logo",upCSS:"upEnable",downCSS:"downEnable"});
        if (window.pageYOffset <= window.innerHeight)
            this.setState({logoStateCSS: "logo",upCSS: "upDisable", downCSS: "downEnable"});
        if ((window.pageYOffset >= window.innerHeight/4))
                this.setState({logoStateCSS: "logoSmall",upCSS:"upEnable",downCSS:"downEnable"});
        if (window.pageYOffset >= document.body.offsetHeight - window.innerHeight )
            this.setState({logoStateCSS: "logoSmall",upCSS:"upEnable",downCSS:"downDisable"});

        event.preventDefault();
    }


    handleDownScroll(event) {
        let dummy={};

        if  (this.state.position<=3) {
            this.setState({position: this.state.position + 1});
            /*window.location.hash = '#' + this.blocks[this.state.position + 1];*/
            dummy = document.getElementById(this.blocks[this.state.position+1]);
            window.scrollTo(dummy.offsetLeft,dummy.offsetTop);
        }
        event.preventDefault();
    }

    handleUpScroll(event)
    {
        if (this.state.position>0) {
            this.setState({position: this.state.position - 1});
            window.location.hash = '#' + this.blocks[this.state.position - 1];
        }
        event.preventDefault();
    }

    render() {
        return(
            <Container className={"Blk0mainDiv"}>
                <div id="block0"></div>
                <Animation/>
                <Image src={logo} className={this.state.logoStateCSS} alt="Aliter Creo Logo"/>
                <Container className="Block0Button">
                    <Row className="Block0Row">
                        <Col className="Block0Col">
                            <Image src={up} className={classnames("up","blink-image",this.state.upCSS)} onClick={this.handleUpScroll} alt="Aliter Creo Up Arrow"/>
                        </Col>
                    </Row>
                    <Row className="Block0Row">
                        <Col className="Block0Col">
                            <Image src={down} className={classnames("down","blink-image",this.state.downCSS)} onClick={this.handleDownScroll} alt="Aliter Creo Down Arrow"/>
                        </Col>
                    </Row>
                </Container>

            </Container>
            );
    }
}

export default Block0;
