import React from "react";
import Radium from "radium";
import {Container} from "react-bootstrap";

let styles={
    contentCSS:{
        padding:'0',
        border: '0',
        margin: '0'
    },
    h1:{
        width:'100%',
        backgroundColor: 'transparent',
        textAlign: 'center',
        color: '#1E75BA',
        fontSize: '23px'
    }
};

class ContentConseil extends React.Component{
    constructor(props) {
        super(props);

        this.state={

        };
    }

    render() {
        return (
            <Container style={styles.contentCSS}>
                <h1 style={styles.h1}>Audit, Conseil, Intégration & Matériel</h1>
                Aliter Creo peut vous accompagner sur tout ou partie de votre projet de Systéme d'Information.
                De l'Audit à l'intégration, en passant par le conseil et le matériel, nous avons toutes les compétences
                pour vous assister dans votre évolution numérique en toute agilité et au plus proche de votre métier.
            </Container>

        );
    }
}

export default Radium(ContentConseil);
