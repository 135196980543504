import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FormControl, InputGroup} from "react-bootstrap";

class MailInput extends React.Component{
    constructor(props) {
        super(props);

        this.state = {valid:false};

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    handleKeyUp(event)
    {
        event.preventDefault();
        if (event.keyCode === 13 && this.state.valid) {
            this.props.onMailSubmit(event);
        }
    }

    handleChange(event){
        event.preventDefault();
        if (this.ValidateEmail(event.target.value))
        {
            event.target.style.color = "green";
            this.props.onChange(event);
            this.setState({valid:true});
        }
        else
        {
            event.target.style.color = "red";
            this.setState({valid:false});
        }
    }

    ValidateEmail(mail)
    {
        return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail));
    }

    render() {
        return (

            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon="at" />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    aria-label={this.props.label}
                    aria-describedby="basic-addon1"
                    title={this.props.title}
                    onChange={this.handleChange}
                    onKeyUp={this.handleKeyUp}
                />
            </InputGroup>
        );
    }
}

export default MailInput;
