import React from "react";
import {FormControl, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class NameInput extends React.Component{
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return(
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon="signature" />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    aria-label={this.props.id}
                    aria-describedby="basic-addon1"
                    title={this.props.title}
                    onChange={this.props.onChange}
                />
            </InputGroup>
        );
    }

}

export default NameInput;
