import React from "react";
import Radium from "radium";
import {Col, Container, Image, Row} from "react-bootstrap";

import IdentifyLogo from "../../Assets/Images/IdentifyLogo.svg";
import IdentifyText from "../../Assets/Images/IdentifyTet.svg";
import ConceptLogo from "../../Assets/Images/ConceptLogo.svg";
import ConceptText from "../../Assets/Images/ConceptText.svg";
import DeployLogo from "../../Assets/Images/DeployLogo.svg";
import DeployText from "../../Assets/Images/DeployText.svg";
import FollowLogo from "../../Assets/Images/FollowLogo.svg";
import FollowText from "../../Assets/Images/FollowText.svg";
import BonitaLogo from "../../Assets/Images/bonitaSoft.png";
import Watson from  "../../Assets/Images/watson.jpg"

let styles={
    contentCSS:{
        padding:'0',
        border: '0',
        margin: '0'
    },
    h1:{
        width:'100%',
        backgroundColor: 'transparent',
        textAlign: 'center',
        color: '#1E75BA',
        fontSize: '23px'
    },
    h3:{
        display: 'inline-block',
    },
    h5:{
        display: 'inline-block',
    },
    logo:{
        maxWidth: '200px',

    },
    logoCol:{
        margin:'10px',

    },
    logotext:{
        height: '30px',
        maxHeight: '100px',
        textAlign: 'left'
    },
    textCol:{
        margin:'10px',

    },
    logoRow:{

    },
    logoSubRow:{

    },
    logoBrand:{
        width: "150px"
    }
};

class ContentBPM extends React.Component{
    constructor(props) {
        super(props);

        this.state={

        };
    }

    render() {
        return (
            <Container style={styles.contentCSS}>
                <h1 style={styles.h1}>BPM</h1>
                <>
                    <h5 style={styles.h5}>L’accélérateur de votre productivité.</h5><br />
                    <p>
                    Maîtrisez-vous dans le détail l’exhaustivité des processus métier de votre entreprise, tous leurs acteurs, toutes les données métier qu’ils portent et tous leurs temps de traitement ?<br />
                    Avez-vous la certitude qu’ils sont tous exécutés correctement ? ou tout simplement exécutés ?<br />
                    Si la réponse à ces deux questions est « non » alors une démarche de <br />« Business Processes Management » ou B.P.M. vous permettra de les ordonner, de les optimiser, de les automatiser et de les suivre.<br />
                        <br />
                    </p>
                    <h5 style={styles.h5}>Les 4 étapes simples et efficaces d’une itération BPM.</h5><br /><br />
                        <Row style={styles.logoRow}>
                            <Col style={styles.logoCol} md={"2"}>
                                <Image src={IdentifyLogo} style={styles.logo} alt="identifer"></Image>
                            </Col>
                            <Col style={styles.textCol} md={"9"}>
                                <Row style={styles.logoSubRow}>
                                    <Image src={IdentifyText}  style={styles.logotext} alt="identifer"></Image>
                                </Row>
                                <Row style={styles.logoSubRow}>
                                    <p>
                                        Aprés avoir déterminé les objectifs de la mission (organisation, optimisation, gain de productivité ...),
                                        nous nous attacherons à cerner le fonctionnement de votre entreprise, de ses activités et de ses acteurs.
                                        Nous spécifierons, enfin, vos principaux processus métier en y intégrant les intervenants, vos applications et les données échangées.
                                        <br />
                                    </p>
                                    </Row>
                            </Col>
                        </Row>
                    <Row style={styles.logoRow}>
                        <Col style={styles.logoCol} md={"2"}>
                            <Image src={ConceptLogo} style={styles.logo} alt="Concevoir"></Image>
                        </Col>
                        <Col style={styles.textCol} md={"9"}>
                            <Row style={styles.logoSubRow}>
                                <Image src={ConceptText}  style={styles.logotext} alt="Concevoir"></Image>
                            </Row>
                            <Row style={styles.logoSubRow}>
                                <p>
                                    Passons au dessin.
                                    En utlisant les symboles de la norme BPMn v.2, nous construirons visuellement votre/vos processus métier en y attachant les données métier nécessaires.

                                </p>

                            </Row>
                        </Col>
                        <Row >
                            <Image src={Watson} alt="BPM" responsive style={{width:"100%"}}/>
                        </Row>
                    </Row>
                    <Row style={styles.logoRow}>
                        <Col style={styles.logoCol} md={"2"}>
                            <Image src={DeployLogo} style={styles.logo} alt="deployer"></Image>
                        </Col>
                        <Col style={styles.textCol} md={"9"}>
                            <Row style={styles.logoSubRow}>
                                <Image src={DeployText}  style={styles.logotext} alt="deployer"></Image>
                            </Row>
                            <Row style={styles.logoSubRow}>
                                <p>
                                    En mode SaaS ou sur vos serveurs, nous mettrons en oeuvre les workflows précédemment
                                    établis dans un environnement full Web accessible sur vos ordinateurs de bureau ou tablettes.
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={styles.logoRow}>
                        <Col style={styles.logoCol} md={"2"}>
                            <Image src={FollowLogo} style={styles.logo} alt="suivre"></Image>
                        </Col>
                        <Col style={styles.textCol} md={"9"}>
                            <Row style={styles.logoSubRow}>
                                <Image src={FollowText}  style={styles.logotext} alt="suivre"></Image>
                            </Row>
                            <Row style={styles.logoSubRow}>
                                <p>
                                    Pour finir, nous mettrons en place un reporting simple et compréhensible afin de vous aider à suivre la bonne exécution de vos
                                    processus et d'éventuellement détecter de nouveaux axes d'amélioration.
                                </p>
                            </Row>
                        </Col>
                    </Row>
                    <p>
                        Ces cycles relativement courts ont le double avantage de mettre rapidement en évidence des améliorations
                        et de pouvoir faire évoluer un processus existant tout aussi rapidement.
                         <br />
                        <br />
                        Si cette démarche novatrice vous intéresse, n'hésitez pas à utiliser le <a href="#block2"> formulaire de contact</a> pour prendre rendez-vous.
                    </p>
                    <Row style={styles.logoSubRow}>
                        <Col style={styles.textCol}>
                            <h3>Notre Technologie favorite :</h3>
                        </Col>
                        <Col md={3}>
                            <a href="http://fr.bonitasoft.com" target="_blank"><Image src={BonitaLogo} style={styles.logoBrand} responsive/></a>
                        </Col>
                    </Row>

                </>
            </Container>
        );
    }
}

export default Radium(ContentBPM);
