import React from "react";


import "./Block2.css";
import "./AllBlocks.css";

import {Col, Container, Row} from "react-bootstrap";
import Contact from "../Blocks/Contact";

class Block2 extends React.Component{
    constructor(props) {
        super(props);

        this.state={};
    }

    render() {
        return(
            <Container className={"Blk1mainDiv"}>
                <div id="block2"></div>
                <Container  className={"Block2Container"}>
                    <Row className={"Block2Row"}>
                        <Col  className={"Block2Col"}>
                            <Contact/>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default Block2;
