import React from "react";

// Import CSS files
import "./Block1.css";
import "./AllBlocks.css";

// Import Components
import ACreoBar from "../panels/ACreoBar";
import {Container, Row} from "react-bootstrap";
import ContentBPM from "../Contents/ContentBPM";
import ContentWebApp from "../Contents/ContentWebApp";
import ContentMateriel from "../Contents/ContentMateriel";
import ContentConseil from "../Contents/ContentConseil";
import ContentQuiSommesNous from "../Contents/ContentQuiSommesNous";

// Import Image
import SmallCogs from "../../Assets/Images/cogs-small.jpg"
import LogoBPM from "../../Assets/Images/BPM.svg"
import SmallConseil from "../../Assets/Images/integration.jpg"
import SmallWebApp from "../../Assets/Images/WebApp-small.jpg"
import LogoWebApp from "../../Assets/Images/logoWebapp.svg"
import LogoIntegration from "../../Assets/Images/logoIntegration.svg";
import SmallMacBook from "../../Assets/Images/macBook-small.jpg";
import logoMateriel from "../../Assets/Images/logoMateriel.svg";
import logoAcreo from "../../Assets/Images/logoAcreo.svg";
import smallDifferent from "../../Assets/Images/smallDifferent.svg";

class Block1 extends React.Component{
    constructor(props) {
        super(props);

        this.state={miniBox:true};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event)
    {
        alert(event.target.id);
        event.preventDefault();
    }

    render() {
        return(
           <Container id="boxDiv" className={"Blk1mainDiv"}>
               <div id="block1"></div>
                   <Container  className={"Block1Container"}>
                       <Row className={"Block1Row"}>
                           <ACreoBar logo={logoAcreo} altLogo="Aliter Creo" img={smallDifferent} altImg="A.creo" content={<ContentQuiSommesNous/>} moreClick={this.handleClick} barId="moreAboutAcreo"></ACreoBar>
                       </Row>
                       <Row className={"Block1Row"}>
                            <ACreoBar logo={LogoBPM} altLogo="B.P.M." img={SmallCogs} altImg="BPM" content={<ContentBPM/>} moreClick={this.handleClick} barId="moreAboutBPM"></ACreoBar>
                       </Row>
                       <Row className={"Block1Row"}>
                           <ACreoBar logo={LogoWebApp} altLogo="Web App" img={SmallWebApp} altImg="WebApp" content={<ContentWebApp/>} moreClick={this.handleClick} barId="moreAboutWebApp"></ACreoBar>
                       </Row>
                       <Row className={"Block1Row"}>
                           <ACreoBar logo={LogoIntegration} altLogo="Conseil" img={SmallConseil} altImg="Conseil" content={<ContentConseil/>} moreClick={this.handleClick} barId="moreAboutConsulting"></ACreoBar>
                       </Row>
                   </Container>
           </Container>
        );
    }
}

export default Block1;

/*
                       <Row className={"Block1Row"}>
                           <ACreoBar logo={logoMateriel} altLogo="Matériels" img={SmallMacBook} altImg="PC" content={<ContentMateriel/>} moreClick={this.handleClick} barId="moreAboutMateriel"></ACreoBar>
                       </Row>
 */
