import React from 'react';
import {ReCaptchaProvider} from 'react-recaptcha-x';

import {library} from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faKey, faAt ,faHome, faGlobeEurope, faUserFriends, faAddressCard,
  faCity,faCrosshairs,faSignature,faFlag,faEye,faCogs,faPlusSquare,faMinusSquare,faPencilAlt,faPhoneAlt} from '@fortawesome/free-solid-svg-icons';

import Block0 from "./Components/Blocks/Block0";
import Block1 from "./Components/Blocks/Block1";
import Block2 from "./Components/Blocks/Block2";
import Footer from "./Components/Blocks/Footer";

import './App.css';


library.add(faCheckSquare, faKey, faAt,faHome,faGlobeEurope,faUserFriends,faAddressCard,
    faCity,faCrosshairs,faSignature,faFlag,faEye,faCogs,faPlusSquare,faMinusSquare,faPencilAlt,faPhoneAlt);

function App() {
  return (
          <ReCaptchaProvider
              siteKeyV3="6Le0qPoUAAAAAI8t5C1Ob8dYlw6uy7l8eI3dBL3P"
              langCode="fr"
              hideV3Badge={true}
          >
              <Block0/>
              <Block1/>
              <Block2/>
              <Footer/>
          </ReCaptchaProvider>
  );
}

export default App;

