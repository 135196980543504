import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./ACreoBar.css"

class ACreoBar extends React.Component{
    constructor(props) {
        super(props);

        this.state={expanded:false};
        this.handleExpand = this.handleExpand.bind(this);
    }

    handleExpand(event)
    {
        this.setState({expanded: !this.state.expanded});
    }

    render() {
        return(
                <Container className="barContainer">
                    <Row className={(!this.state.expanded)?"barRow": "barRowExpanded"}>
                        <Col className="barLeftCol">
                            <Image src={this.props.logo} className="barLeftLogo" alt={this.props.altLogo}/>
                        </Col>
                        <div className={(!this.state.expanded)?"barMiddleCol": "barMiddleColExpanded"}>
                            <div className={(!this.state.expanded)?"barMiddleText": "barMiddleTextExpanded"}>
                                  {this.props.content}
                            </div>
                            <div  className={"moreBar"} onClick={this.handleExpand} id={this.props.barId}>
                                <FontAwesomeIcon icon={(!this.state.expanded)?"plus-square": "minus-square"}/>
                            </div>
                        </div>
                        <Col className={(!this.state.expanded)?"barRightCol": "barRightColExpanded"}>
                            <Image src={this.props.img} className="barRightPic" alt={this.props.altImg}/>
                        </Col>
                    </Row>
                </Container>
        );
    }

}

export default ACreoBar;
