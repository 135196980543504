import React from "react";
import Radium from "radium";
import {Col, Container, Image, Row} from "react-bootstrap";
import reactLogo from "../../Assets/Images/react.svg";
import nodeLogo from "../../Assets/Images/nodejs.svg";
import htmlLogo from "../../Assets/Images/html5.svg";
import cssLogo from "../../Assets/Images/css3.svg";
import TFLogo from "../../Assets/Images/TensorFlow.png";
import PythonLogo from "../../Assets/Images/python.png"

let styles={
    contentCSS:{
        padding:'0',
        border: '0',
        margin: '0'
    },
    h1:{
        width:'100%',
        backgroundColor: 'transparent',
        textAlign: 'center',
        color: '#1E75BA',
        fontSize: '23px'
    },
    logo:{
        maxWidth: '200px',

    },
    logoCol:{
        margin:'10px',

    },
    logotext:{
        height: '30px',
        maxHeight: '100px',
        textAlign: 'left'
    },
    textCol:{
        margin:'10px',

    },
    logoRow:{

    },
    logoSubRow:{

    },
    logoBrand:{
        width: "100px",
        //height : "100px"
    }
};

class ContentWebApp extends React.Component{
    constructor(props) {
        super(props);

        this.state={

        };
    }

    render() {
        return (
            <Container style={styles.contentCSS}>
                <h1 style={styles.h1}>Web App & Machine Learning</h1>
                <h5>Le Client/Serveur est mort ! Vive le Client/Serveur !</h5>
                Les WebApps sont plus qu'une alternative aux clients dits lourds, elles sont l'avenir applicatif.
                Les technologies mises à notre disposition aujourd'hui nous permettent de tout faire, de tout réaliser au sein d'un navigateur web qu'il soit sédentaire
                ou nomade.<br />
                Les experts FullStack d'Aliter Creo sont à votre service pour réaliser toute application spécifique en mode Web, SaaS ou on-Premise. Tout est possible,
                la seule limite étant votre imagination.<br /><br />

                <h3>Nos Technologies favorites :</h3>
                <Row style={styles.logoSubRow}>
                    <Col md={3}>
                        <a href="https://fr.reactjs.org" target="_blank"><Image src={reactLogo} style={styles.logoBrand} responsive alt="ReactJs React Native"/></a>
                    </Col>
                    <Col md={3}>
                        <a href="https://www.nodejs.org" target="_blank"><Image src={nodeLogo} style={styles.logoBrand} responsive alt="NodeJs"/></a>
                    </Col>
                    <Col md={3}>
                        <a href="https://www.w3.org" target="_blank"><Image src={htmlLogo} style={styles.logoBrand} responsive alt="Html5"/></a>
                    </Col>
                    <Col md={3}>
                        <a href="https://www.w3.org" target="_blank"><Image src={cssLogo} style={styles.logoBrand} responsive alt="CSS3"/></a>
                    </Col>
                </Row>
                <br />
                <h5>Des applications intelligentes.</h5>
                Détections, reconnaissances, prédictions, ...
                Nous pouvons mettre en place ces fonctionnalités au sein des applications que nous développons.
                N'hésitez pas à nous faire part de vos projets. Nous nous ferons un plaisir de vous aider à les réaliser.

                <br /><br />

                <h3>Nos Technologies favorites :</h3>
                <Row style={styles.logoSubRow}>
                    <Col md={3}>
                        <a href="https://github.com/tensorflow/tensorflow" target="_blank"><Image src={TFLogo} style={styles.logoBrand} responsive alt="TensorFlow"/></a>
                    </Col>
                    <Col md={3}>
                        <a href="https://www.python.org" target="_blank"><Image src={PythonLogo} style={styles.logoBrand} responsive alt="Python"/></a>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Radium(ContentWebApp);
