import React from "react";
import Radium from "radium";
import {Container} from "react-bootstrap";

let styles={
    contentCSS:{
        padding:'0',
        border: '0',
        margin: '0'
    },
    h1:{
        width:'100%',
        backgroundColor: 'transparent',
        textAlign: 'center',
        color: '#1E75BA',
        fontSize: '23px'
    }
};

class ContentMateriel extends React.Component{
    constructor(props) {
        super(props);

        this.state={

        };
    }

    render() {
        return (
            <Container style={styles.contentCSS}>
                <h1 style={styles.h1}>Matériel</h1>

            </Container>
        );
    }
}

export default Radium(ContentMateriel);
