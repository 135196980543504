import React from "react";


import "./Footer.css";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";

class Footer extends React.Component{
    constructor(props) {
        super(props);

        this.state={show:false};
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
    }

    handleShow()
    {
        this.setState({show:true});
    }

    handleHide()
    {
        this.setState({show:false});
    }

    render() {
        return(
            <Container className="FooterContainer">

                <div id="footer"></div>
                    <Row style={{padding:0,margin:0,width:"100%"}}>
                        <Col style={{textAlign:"left"}}>
                            <Button variant="link" className="mylink" onClick={this.handleShow}>Mentions légales</Button>
                        </Col>
                        <Col style={{textAlign:"right"}}>
                            <label>2020 &#9400; Aliter Creo</label>
                        </Col>
                    </Row>

                <Modal show={this.state.show} onHide={this.handleHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mentions Légales</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Éditeur :</p>
                        <p>
                            Aliter Creo S.A.S. au capital de 1 000 €<br/>
                        RCS Reims 882 079 643<br/>
                        SIRET 88207964300012<br/>
                            APE 6202A<br/>
                            TVA FR80882079643<br/>
                        Siège social : 2B rue des jancelins 51200 Epernay FRANCE<br/>
                        N° de téléphone : 0 636 076 550<br/>
                        </p>
                        <p>
                        Hébergement :<br/>
                            <br/>
                            OVH S.A.S. au capital de 10 069 020 €<br/>
                            RCS Lille Métropole 424761419 <br/>
                            SIRET 42476141900045<br/>
                            APE 2620Z<br/>
                            TVA FR22424761419<br/>
                            Siège social : 2 rue Kellermann 59100 Roubaix FRANCE<br/>
                        </p>
                        <p>
                        Protection des données personnelles :<br/>

                        Droit d'accès et de modification<br/>
                        Conformément aux articles 39 et suivants de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
                        Toute personne peut obtenir communication et, le cas échéant, rectification ou suppression des informations la concernant, en s'adressant par courrier à :<br/>

                        Aliter Creo<br/>
                        2B rue des jancelins<br/>
                        51200 EPERNAY<br/>
                        contact.at.a-creo-com<br/>
                            <br/>
                        Crédits photos :<br/>
                        Aliter Creo<br/>
                        pexels.com<br/>

                            <br/>
                        Directeur de la publication :<br/>
                        Frédéric GALLET<br/>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleHide}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default Footer;
