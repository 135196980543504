import React from "react";
import Radium from "radium";
import {Col, Container, Image, Row} from "react-bootstrap";

import Fred from "../../Assets/Images/fred.JPG"

let styles={
    contentCSS:{
        padding:'0',
        border: '0',
        margin: '0'
    },
    h1:{
        width:'100%',
        backgroundColor: 'transparent',
        textAlign: 'center',
        color: '#1E75BA',
        fontSize: '23px'
    }
};

class ContentQuiSommesNous extends React.Component{
    constructor(props) {
        super(props);

        this.state={

        };
    }

    render() {
        return (
            <Container style={styles.contentCSS}>
                <h1 style={styles.h1}>Aliter Creo ?</h1>
                <>
                Chez Aliter Creo, nous cultivons les alternatives technologiques et méthodologiques.
                Nous sortons des sentiers battus (et rebattus) pour mieux créer et servir nos clients désireux de
                    réussir leur transition numérique.<br />
                Ce site vous donnera un aperçu de la transversalité des services que nous pouvons vous rendre.<br />
                    <br />
                « Créer autrement » est notre Credo.<br />
                    <br />
                Bonne visite.
                    <br /><br />
                <Row style={{height:"100px"}}>
                <Col style={{margin:"auto",padding:0,verticalAlign:"middle"}}>
                    <div>
                    Frédéric GALLET<br />
                    Président d’Aliter Creo<br />
                    </div>
                </Col>
                    <Col style={{textAlign: "right",margin:"auto",padding:0}}>
                    <Image src={Fred} style={{width:"100px", overflow:"hidden",}}roundedCircle/>
                    </Col>
                    <br /><br />
                </Row>
                </>
            </Container>
        );
    }
}

export default Radium(ContentQuiSommesNous);
