import React from "react";
import axios from "axios";
import qs from "querystring";
import classnames from "classnames";

import "../Blocks/Contact.css"
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import MailInput from "../Inputs/MailInput";
import NameInput from "../Inputs/NameInput";
import TextInput from "../Inputs/TextInput";
import {ReCaptchaV3} from 'react-recaptcha-x';
import PhoneInput from "../Inputs/PhoneInput";

function markdown(input){
    return {
        __html : input,
    }
}

class Contact extends React.Component{

    constructor(props) {
        super(props);

        this.state={minimize:true,firstname:"",lastname:"",phone:"",mail:"",message:"",recaptcha:"",show:false,modalMessage:"",token:undefined,getToken:undefined,subject:""};
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.v3Callback = this.v3Callback.bind(this);
    }

    v3Callback(token, refreshToken)
    {
        if (typeof token === 'string') {
            //console.log('this is the token', token);
            this.setState({token:token});
            if (typeof refreshToken === 'function') {
                //console.log('this is the refresh token function', refreshToken);
                this.setState({getToken:refreshToken});
            }
        } else {
            //console.log('token retrieval in progress...');
        }
    }

    handleClose()
    {
        this.setState({show:false,modalMessage:[]});
    }

    handleShow()
    {
        this.setState({show:true});
    }

    handleSubmit()
    {
        let complete=true;
        let message=[];

        this.state.getToken()


        if(this.state.firstname === "")
        {
            message += "Veuillez saisir votre prénom.<Br>";
            complete = false;
        }
        if (this.state.lastname === "") {
            message  += "Veuillez saisir votre nom.<Br>";
            complete = false;
        }
        if (this.state.phone === "") {
            message += "Veuillez saisir votre numéro de téléphone.<Br>";
            complete = false;
        }
        if (this.state.mail === "") {
            message += "Veuillez saisir votre adresse mail.<Br>";
            complete = false;
        }
        if (this.state.subject === "") {
            message += "Veuillez saisir le sujet du message.<Br>";
            complete = false;
        }
        if (this.state.message === "") {
            message += "Veuillez saisir votre message.";
            complete = false;
        }

        this.setState({modalMessage: message});

        if (!complete)
            this.setState({show: true});
        else {
            const requestBody = {
                recaptcha_response: this.state.token,
                firstName: this.state.firstname,
                lastName: this.state.lastname,
                phone: this.state.phone,
                mail: this.state.mail,
                message: this.state.message,
                subject: this.state.subject
            };

            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };

            axios.post('https://www.a-creo.com/contact.php', qs.stringify(requestBody), config)
                .then(function (response) {
                   //console.log(response);
                    alert('email envoyé.');
                })
                .catch(function (error) {
                    //console.log(error);
                    alert('Envoi de mail impossible.');
                });
        }
    }

    handleClick(event)
    {
        this.setState({minimize:!this.state.minimize});
        event.target.preventDefault();
    }

    handleChange(event)
    {
        switch (event.target.id) {
            case "ContactFirstName":
                this.setState({firstname:event.target.value});
                break;
            case "ContactLastName":
                this.setState({lastname:event.target.value});
                break;
            case "ContactPhone":
                this.setState({phone:event.target.value});
                break;
            case "ContactMail":
                this.setState({mail:event.target.value});
                break;
            case "ContactMessage":
                this.setState({message:event.target.value});
                break;
            case "ContactSubject":
                this.setState({subject:event.target.value});
                break;
            default:
                break;
        }
        event.preventDefault();
    }

    render() {
        const mini = this.state.minimize;
        return (
            <>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Erreur</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={markdown(this.state.modalMessage)}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>

            <Container className={classnames("container-fluid","d-flex","flex-column","myshadowContact", mini?"":"largeShadow")}>
                <Row className={"BoxTitle"}>
                    <Col>
                        <span className={"TextTitle"}>Contact</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br/>
                    </Col>
                </Row>
                <Row className="flex-fill">
                    <Col className="BoxContent d-flex flex-column">
                        <NameInput id="ContactFirstName" placeholder="Prénom" onChange={this.handleChange}/>
                        <NameInput id="ContactLastName" placeholder="Nom" onChange={this.handleChange}/>
                        <PhoneInput id="ContactPhone" placeholder="Téléphone" onChange={this.handleChange}/>
                        <MailInput id="ContactMail" placeholder="E-mail" onChange={this.handleChange}/>
                        <NameInput id="ContactSubject" placeholder="Sujet" onChange={this.handleChange}/>
                        <TextInput id="ContactMessage" placeholder="Message" onChange={this.handleChange}/>
                    </Col>
                </Row>
                <Row className="flex-fill">
                    <Col>
                        <ReCaptchaV3 action="/contact" callback={this.v3Callback} />
                    </Col>
                    <Col className="BoxContent d-flex flex-column align-self-center">
                        <Button onClick={this.handleSubmit} style={{background:"#1E75BA", color:"antiquewhite"}}>Envoyer</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br/>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }

}

export default Contact;
